import { React, useState, useEffect } from "react";
import { getToken } from "../../components/auth";



const Login = () => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [returnResult, setReturnResult] = useState(0);
    const [tokInfo, setTokInfo] = useState("");
    
    const payload = {
        emailId: email,
        password: password,
    };
    let tok_result = "";
    let tok_obj = {};
    
   const sendLogin = async (event) => {
    event.preventDefault();


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };
        const response = await fetch('https://lab.vanblaricom.com/flask/reactlogin', requestOptions);
        console.log("response code = " + response.status);
        const result = await response.json();
        if (result['access_token'] == null) {
            console.log("result: access_token is null");
        }
        else if (result['access_token'].length == 0) {
            console.log("result: access_token is empty");
        }
        else {
            console.log(`result: ${result['access_token']}`);
        }
    //    .then((response) => {
    //        const result = response.json()
    //        localStorage.setItem("result", JSON.stringify(result))
    //        localStorage.setItem("alt_result", result)
    //    });
//        .then((response) => response.json());
    //    console.log("Response body: " + contents.text);
    //    const returned_json = contents.json();
    //    console.log("Returned JSON: " + returned_json);
    //    window.alert("okay " + content);
    //    const results = await fetch('https://lab.vanblaricom.com/flask/reactlogin', requestOptions);
    //        .then(response => response.json())
    //        .then(json => window.alert("results "));

    //    axios.post("https://lab.vanblaricom.com/flask/reactlogin", payload)
    //    .then((response) => {
    //        const result = response;
    //        localStorage.setItem("result", JSON.stringify(result))
    //    });

    //    event.preventDefault();

    
    }
    
    useEffect = (() => {
        sendLogin()
    }, []);

    const procTokInfo = (tokStr) => {
        const tokObj = JSON.parse(tokStr);
        console.log(tokObj['access_token']);
    }

    return (
        <div className="container">
            <div className="container">
                <h2>Login</h2>
            </div>
                    <form onSubmit={sendLogin}>
                        <div className="mb-3 mt-3 w-50">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Email address
                            </label>
                            { /*procTokInfo(getToken("sam", "jjj"))*/ }
                            { /*console.log(tokInfo)*/ }
                            { /*tok_obj = JSON.parse(tok_result)*/ }
                            { /*console.log(tok_obj['access_token'])*/ }
                            <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="enter email address"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3 w-50">
                            <label htmlFor="exampleInputPassword1" className="form-label">
                                Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="enter password"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                        </div>
                        <div>
                            <label>
                                {}
                            </label>
                        </div>
                        <button type="submit" className="btn btn-primary"> 
                            Login to account
                        </button>
                    </form>
            </div>
        );
    };
export default Login;