//import React, { useState, useEffect } from 'react';
//import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import logo from './logo.svg';
import Login from './pages/Login';
import Registration from './pages/Registration'
import './App.css';

function App() {
/*
  const [data, setData] = useState({
    name: "",
    age: 0,
    date: "",
    programming: "",
  });

  useEffect(() => {
    fetch("http://192.168.200.66:3333/data").then((res) =>
      res.json().then((data) => {
        setData({
          name: data.Name,
          age: data.Age,
          date: data.Date,
          programming: data.Programming,
        });
      })
    );
  }, []);
*/
  return (
    <div className="wrapper">
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={ <Login />} />           
          <Route path="/registration" element={  <Registration /> } />
          <Route path="/" element={  <Login /> } />
          <Route
            path="*"
            element={
              <div>
                <h2>404 Page not found</h2>
              </div>
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
