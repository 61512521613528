

function getToken(username, password) {
    console.log(`username: ${username}, password: ${password}`);
    const new_token = JSON.stringify({ access_token: "eykkkgfjrnemw",
        refresh_token: "ey9dd7jj2ksdidfkr",
        exp_time: 1590002367
    });
    return new_token;
}


module.exports = { getToken: getToken };