import { React, useState, useEffect } from "react";

const Registration = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [confirmPassword, setConfirmPassword] = useState("");
    const [adminStatus, setAdminStatus] = useState(false);
    const [selectStatus, setSelectStatus] = useState(true);
    const [updateStatus, setUpdateStatus] = useState(false);
    const [createStatus, setCreateStatus] = useState(false);
    const [deleteStatus, setDeleteStatus] = useState(false);

    const toggleAdminStatus = () => { setAdminStatus(!adminStatus); }
    const toggleSelectStatus = () => { setSelectStatus(!selectStatus); }
    const toggleUpdateStatus = () => { setUpdateStatus(!updateStatus); }
    const toggleCreateStatus = () => { setCreateStatus(!createStatus); }
    const toggleDeleteStatus = () => { setDeleteStatus(!deleteStatus); }
    
    const payload = {
        emailId: email,
        password: password,
    };
    
   const sendLogin = async (event) => {
    event.preventDefault();


        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify(payload)
        };
        fetch('https://lab.vanblaricom.com/flask/reactregistration', requestOptions)
        .then((response) => {
            const result = response;
            localStorage.setItem("result", JSON.stringify(result))
        });
    //    console.log("Response body: " + contents.text);
    //    const returned_json = contents.json();
    //    console.log("Returned JSON: " + returned_json);
    //    window.alert("okay " + content);
    //    const results = await fetch('https://lab.vanblaricom.com/flask/reactlogin', requestOptions);
    //        .then(response => response.json())
    //        .then(json => window.alert("results "));

    //    axios.post("https://lab.vanblaricom.com/flask/reactregistration", payload)
    //    .then((response) => {
    //        const result = response;
    //        localStorage.setItem("result", JSON.stringify(result))
    //    });

        event.preventDefault();

    
    }
    
    useEffect = (() => {
        sendLogin()
    }, []);

    return (
        <div className="container">
            <div className="container">
                <h2>Registration</h2>
                {console.log(`selectStatus: ${ selectStatus } adminStatus: ${ adminStatus } updateStatus: ${ updateStatus }`)};
            </div>
                    <form onSubmit={sendLogin}>
                        <div className="mb-3 mt-3 w-50">
                            <label htmlFor="exampleInputEmail1" className="form-label">
                                Email address
                            </label>
                            <input
                                type="email"
                                className="form-control"
                                id="exampleInputEmail1"
                                aria-describedby="emailHelp"
                                placeholder="enter email address here"
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </div>
                        <div className="mb-3 w-50">
                            <label htmlFor="exampleInputPassword1" className="form-label">
                                Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="exampleInputPassword1"
                                placeholder="enter password here"
                                onChange={(e) => setPassword(e.target.value)}
                            />
                            <label htmlFor="exampleInputPassword1" className="form-label">
                                Confirm Password
                            </label>
                            <input
                                type="password"
                                className="form-control"
                                id="confirmInputPassword1"
                                placeholder="confirm password here"
                                onChange={(e) => setConfirmPassword(e.target.value)}
                            />
                            <label>
                                {password.length > 0 ? password === confirmPassword ? "Password confirmed" : "Confirmation password does not match" : " "}
                            </label>
                            
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value={selectStatus} id="selectCheckBox" onChange={toggleSelectStatus} defaultChecked/>
                                <label class="form-check-label" for="selectCheckBox">
                                    Select
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value={updateStatus} id="updateCheckBox" onChange={toggleUpdateStatus}/>
                                <label class="form-check-label" for="updateCheckBox">
                                    Update
                                </label>
                            </div>
                            <div class="form-check">
                                 <input class="form-check-input" type="checkbox" value={createStatus} id="createCheckBox" onChange={toggleCreateStatus}/>
                                <label class="form-check-label" for="createCheckBox">
                                    Create
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value={deleteStatus} id="deleteCheckBox" onChange={toggleDeleteStatus}/>
                                <label class="form-check-label" for="deleteCheckBox">
                                    Delete
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="checkbox" value={adminStatus} id="adminCheckBox" onChange={toggleAdminStatus}/>
                                <label class="form-check-label" for="adminCheckBox">
                                    Admin
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="btn btn-primary"> 
                            Add Account
                        </button>
                    </form>
            </div>
        );
    }
export default Registration;